export interface HomepageTickerNavViewModel {
    Prices: ChangedPriceItem[];
    TickerNavMetaData: HomepageDailyPriceViewModel;
}

export interface ChangedPriceItem {
    Ticker: string;
    AsOfDate: string;
    NavCurrent: number;
    NavDailyChange: number;
    FundUrl: string;
}

export interface CategorisedFundItems {
    AsOfDate: string;
    FormattedAsOfDate: string;
    Items: ChangedPriceItem[]
}

export interface HomepageDailyPriceViewModel {
    TabId: string;
    GroupId: string;
    TabText: string;
    TabSwitchNavId: string;
    LatestNavText: string;
    AsOfDateText: string;
    NavVaneckDtpText: string;
    HeaderLabels: string;
    DataRows: string[];
    PriceReturnsText: string;
    PriceReturnsLink: string;
    TabClass: string;
    TabCssClass: string;
    TabPaneCssClass: string;
    TabSelected: string;
    GroupDisplayStyle: string;
}

export function processPricesData(pricesData: ChangedPriceItem[]) {
    let result: CategorisedFundItems[] = [];

    pricesData.forEach(priceItem => {
        let categoryItem = result.find(x => x.AsOfDate === priceItem.AsOfDate);
        if (categoryItem) {
            return categoryItem.Items.push(priceItem);
        }
        let newCategory: CategorisedFundItems = {
            AsOfDate: priceItem.AsOfDate,
            FormattedAsOfDate: convCSDate(priceItem.AsOfDate),
            Items: [priceItem]
        }
        return result.push(newCategory);
    })

    return result;
}

function convCSDate(dateString: string) {
    dateString = dateString.replaceAll(/[^0-9]/g, "");
    if (typeof dateString != "string") {
        return "Error";
    }
    var date = new Date(Number(dateString));

    var year = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(date);
    var month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    var day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);

    //NAV as of 05/10/23
    return `NAV as of ${day}/${month}/${year}`;
}

export const mkChangedTab = (dataSet: CategorisedFundItems, tab: HomepageDailyPriceViewModel, columnSize = 6) => {

    let markup = "";
    const asOfDate = dataSet.FormattedAsOfDate;
    markup += `<div class="ticker-nav__set"><div class="ticker-nav__timestamp">${asOfDate}</div><div class="ticker-nav__row">`
    let SplitChildList = [];

    //splitting data to arrays of 6 items - Childlist defined at the top
    for (let i = 0; i < dataSet.Items.length; i += columnSize) {
        const chunk = dataSet.Items.slice(i, i + columnSize);
        SplitChildList.push(chunk);
    }

    //This is the expanded view where each column is rendered seperately.
    markup += SplitChildList.reduce((prev, curr) => {
        //Iterating though each column...
        var mk = `<div class="ticker-nav__column">`;
        mk += curr.reduce((p, c) => {
            //Now iterate through each fund...
            return p + mkFund(c);
        }, "");
        mk += `</div>`;
        return prev + mk;
    }, "");

    markup += `</div></div>`;

    return markup;
}


//draw each fund item
const mkFund = (fund: ChangedPriceItem) => {
    return `<div class="ticker-nav__item"><a class="ticker-nav__ticker-text" href="${fund.FundUrl}">${fund.Ticker}</a>
                    <span class="ticker-nav__meta-content">
                        <span class="ticker-nav__price-text" vaneck_dtp="NAV_${fund.Ticker}">$${Number(fund.NavCurrent).toFixed(2)}</span>
                        (${mkLastChange(fund.NavDailyChange)})
                   </span>
                </div>`;
};


//draw the last change value
const mkLastChange = (amount: number) => {
    const formattedAmount = Number(amount).toFixed(2);
    if (amount < 0) return getSVG("fund-decrease", "$" + formattedAmount);
    return getSVG("fund-increase", "$" + formattedAmount);
};


//to create the CTA link
export const mkCTA = (linkURL: string, linkTitle: string) => {
    return `<a class="cta-link ticker-nav__cta" href="${linkURL}">
                <span class="cta-link__text text-util-md">
                    ${linkTitle}${getSVG("angle-right")}
                </span>
            </a>`
};

function getSVG(name: string, text?: string) {
    switch (name) {
        case "chevron-up":
            return `<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-up" class="svg-inline--fa fa-chevron-up fa-w-16 fa-lg icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path fill="currentColor" d="M15.74 13.12a-.75-.75 0 00.54-.21-.75-.75 0 000-1.07L9.53 5.1a-.75-.75 0 00-1.06 0l-6.75 6.75a-.75-.75 0 000 1.07-.75-.75 0 001.06 0L9 6.69l6.22 6.22a-.75-.75 0 00.52.21z"></path></svg>`;
        case "chevron-down":
            return `<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-16 fa-lg icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path fill="currentColor" d="M2.26 4.88a.75.75 0 00-.54.21.75.75 0 000 1.07l6.75 6.75a.75.75 0 001.06 0l6.75-6.75a.75.75 0 000-1.07.75.75 0 00-1.06 0L9 11.31 2.78 5.1a.75.75 0 00-.52-.21z"></path></svg>`
        case "angle-right":
            return `<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-16 fa-lg cta-link__icon align-middle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M10 7.25a.75.75 0 00-.53.22.75.75 0 000 1.06L12.94 12l-3.47 3.47a.75.75 0 000 1.06.75.75 0 001.06 0l4-4a.75.75 0 000-1.06l-4-4a.75.75 0 00-.52-.22z"></path></svg>`;
        case "fund-increase":
            return `<span class="ticker-nav__delta-text ticker-nav__delta-text--increase"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" class="svg-inline--fa fa-caret-up fa-w-16 fa-sm icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path fill="currentColor" d="M9.5 8.15h-9L5 1.85z"></path></svg>&nbsp;${text}</span>`
        case "fund-decrease":
            return `<span class="ticker-nav__delta-text ticker-nav__delta-text--decrease"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" class="svg-inline--fa fa-caret-down fa-w-16 fa-sm icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path fill="currentColor" d="M.5 1.85h9L5 8.15z"></path></svg>&nbsp;${text}</span>`;
        default:
            return "";
    }
}