//require("regenerator-runtime/runtime");
import { processPricesData, mkChangedTab, mkCTA } from "./ticker-nav-functions.ts";

window.veNavTickers = {
    initialised: false,
    data: [],
    container: "div.ticker-nav__tabs",
    dropdownTitle: "ETF NAVs",
    dropdownList: [
        { displayValue: "Volume", sortHeader: "Volume", sortOrder: "desc" }, 
        { displayValue: "Ticker", sortHeader: "Ticker", sortOrder: "asc" },
        { displayValue: "Net Assets", sortHeader: "AUM", sortOrder: "desc" },
        // { displayValue: "Net Expense Ratio", sortHeader: "NetExpenseRatio", sortOrder: "desc" },
        { displayValue: "Price Change", sortHeader: "DailyChangeNav", sortOrder: "desc" }
        // { displayValue: "30-Day SEC Yield", sortHeader: "ThirtyDaySecYield", sortOrder: "desc" }
    ],
    initialSortItem: { displayValue: "Volume", sortHeader: "Volume", sortOrder: "desc" },
    columnSize: 6,
    linkTitle: "View More Details",
    linkURL:"/vaneck-etfs/?page=etf-selector",
    APIURL: "/Australia/HomeAuPage/GetDailyPrices",

    getData: async x => {
        try {
            var data = await $.get(veNavTickers.APIURL);
            return data;
        }
        catch (err) {
            console.log("Error getting data");
            console.log(err);
            return []
        }
    },

    //draw the dropdown section
    rnDropdown: (data, showDropdowns = true) => {
        var markup =
            `<div class="d-flex home-fund-dropdown align-items-center justify-content-between pt-2">
                <div class="dropdown-main-header">${data.LatestNavText}</div>
                <div class="section-header__dropdown ml-2 ${!showDropdowns ? "d-none" : ""}">
                    <h2 class="sr-only">Fund Dropdown</h2>
                    <div class="dropdown" id="HomeFundDropdown" data-title="${veNavTickers.initialSortItem.sortHeader}" data-order="${veNavTickers.initialSortItem.sortOrder}" data-widgetstate="expanded">
                        <button data-ve-gtm="collection-dropdown" aria-haspopup="true" aria-expanded="true" class="dropdown__button text-util-md dropdown__button-condensed" data-toggle="dropdown" id="" type="button">
                            <span class="dropdown__label">Volume</span>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-16 fa-lg dropdown__icon dropdown__icon-condensed" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                                <path fill="currentColor" d="M2.26 4.88a.75.75 0 00-.54.21.75.75 0 000 1.07l6.75 6.75a.75.75 0 001.06 0l6.75-6.75a.75.75 0 000-1.07.75.75 0 00-1.06 0L9 11.31 2.78 5.1a.75.75 0 00-.52-.21z">
                                </path>
                            </svg>
                        </button>
                        <div class="dropdown-menu dropdown__menu text-util-md" role="menu" style="">`;
        showDropdowns && veNavTickers.dropdownList.forEach(function (item) {
            markup += `<button class="dropdown-item dropdown__item text-util-md" role="menuitem" data-title="${item.sortHeader}" data-order="${item.sortOrder}" type="button">${item.displayValue}</button>`
        });
        markup += `</div></div></div><div class="CTA-placeholder">${mkCTA(data.PriceReturnsLink, data.PriceReturnsText)}</div></div>`;
        markup += `<div class="fund-widget tabs__content tab-content"></div>`
        return markup
    },


    //draw the main data content
    rnTabContent: (data, pricesData) => {
        var markup = ``;
        $('.fund-widget.tabs__content.tab-content').empty();
        //Iterate though high level tabs
        markup += `<div class="tab-pane fade ${data.TabPaneCssClass}" id="${data.TabId}" role="tabpanel" aria-labelledby="${data.TabId}-tab">`;
        markup += `<div class="ticker-nav__content">`;
        markup += veNavTickers.mkTabInnerContent(data, pricesData);
        markup += "</div></div>";
        $('.fund-widget.tabs__content.tab-content').append(markup);
    },

    mkTabInnerContent: (data, pricesData) => {
        var markup = `<div class="ticker-nav__content-inner"><div class="tab-content">`;
        markup += veNavTickers.mkTabFundContent(data, pricesData);
        markup += `</div></div>`;
        return markup;
    },

    //drawing the contents based on the array available
    mkTabFundContent: (tab, pricesData) => {
        const sortHeader = $("#HomeFundDropdown").attr("data-title");
        const sortOrder = $("#HomeFundDropdown").attr("data-order");

        const TabPaneCssClass = "active show";
        //const TabPaneCssClass = isExpanded && type == "collapsed" ? "" : "active show";

        const categoriedPricesData = processPricesData(pricesData);

        console.log(categoriedPricesData);

        var markup = `<div class="tab-pane ${TabPaneCssClass}" id="expanded-view" role="tabpanel" aria-labelledby="expanded-tab">`
        categoriedPricesData.forEach(dataSet => {
            markup += mkChangedTab(dataSet);
        })
        markup += `</div>`;
        return markup;
    },

    //sort data based on the order and the title
    rnSortData: (dataSet, sortTitle, sortType) => {
        var newArray = dataSet.sort((a, b) => {
            if (a[sortTitle] == null || b[sortTitle] == null) {
                return b[sortTitle] == null ? -1 : 0;
            }
            let la, lb;
            //if string the data is converted to lowercase for comparison
            if (isNaN(a[sortTitle]) && isNaN(b[sortTitle])) {
                la = a[sortTitle].toLowerCase(), lb = b[sortTitle].toLowerCase();
            } else {
                la = Number(a[sortTitle]), lb = Number(b[sortTitle]);
            }

            if (sortType == "asc") {
                if (la < lb) return -1;
                if (la > lb) return 1;
                return 0;
            } else {
                if (la > lb) return -1;
                if (la < lb) return 1;
                return 0;
            }
        })
        return newArray
    },

    init: async x => {
        if ($(".ticker-nav").length === 0) return;
        veNavTickers.response = await veNavTickers.getData();

        veNavTickers.data = veNavTickers.response.TickerNavMetaData;
        veNavTickers.prices = veNavTickers.response.Prices;

        if (!veNavTickers.data || veNavTickers.data.length == 0) {
            console.info("Failed to load nav tickers: Did not receive response.")
            $(".ticker-nav .ticker-nav__tabs").hide();
            return;
        }
                
        ["linkTitle", "dropdownTitle"].forEach(e => {
            veNavTickers[e] = $(veNavTickers.container).data(e.toLowerCase()) ?? veNavTickers[e]
        })

        var markup = veNavTickers.rnDropdown(veNavTickers.data, false);
        $(veNavTickers.container).html(markup);
        $("div.ticker-nav").removeClass("d-none");
        veNavTickers.rnTabContent(veNavTickers.data, veNavTickers.prices);
    }
}

async function Bootstrap() {
    await veNavTickers.init();
}

window.addEventListener("DOMContentLoaded", Bootstrap);

